<template>
  <div @contextmenu.prevent @click="antiScreenshotDisableClick">
    <div id="AntiPrint" class="hidden">
      <img src="https://connect-cdn.intellectualpoint.com/assets/images/misc/copyright-infringement.jpg" width="500" class="mb-5" />
      <h1 class="text-white">Printing, Recording, Screenshotting or Sharing ExamEdge Is Not Allowed<br /><br /></h1>
      <h1 class="text-white">{{ activeUserInfo.id }}</h1>
      <h1 class="text-white">{{ activeUserInfo.firstName }} {{ activeUserInfo.lastName }}</h1>
      <h1 class="text-white">{{ activeUserInfo.email }}</h1>
    </div>
    <div id="TestPrepRenderer">
      <div class="TpAppScreen flex items-center justify-center overflow-hidden">
        <div class="TpAppParent">
          <div class="TpAppContainer">
            <slide-y-down-transition>
              <div v-if="appReady && !complete.active">
                <div class="TpBlockSelect mb-4 progress-bar-container">
                  <div class="flex items-center justify-between mb-2 quiz-header" v-if="assignmentData">
                    <h1 class="font-light text-4xl" id="quiz-mode">{{ assignmentData.name }}</h1>

                    <div id="time-parent-container">
                      <div class="flex justify-between gap-x-6 items-center" id="time-container">
                        <div class="flex justify-center items-center" id="question-count" v-if="quizData">
                          <lottie-animation
                            path="https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_nesztu9e.json"
                            class="w-6 h-6 mr-1"
                            :loop="true"
                          />
                          <p class="font-light" style="margin-top: 0.1rem">{{ quizData.total }} Questions</p>
                        </div>
                        <span class="text-primary" id="time-separator">|</span>
                        <div class="flex justify-center items-center" id="time-count">
                          <p class="font-light" v-if="timer.display">
                            <span v-if="timer.display.hours"
                              >{{ timer.display.hours }} {{ timer.display.hours === 1 ? 'Hour' : 'Hours' }} {{ timer.display.minutes }}
                              {{ timer.display.minutes === 1 ? 'Minute' : 'Minutes' }}</span
                            >
                            <span v-if="!timer.display.hours && timer.display.minutes"
                              >{{ timer.display.minutes }} {{ timer.display.minutes === 1 ? 'Minute' : 'Minutes' }}</span
                            >
                            <span v-if="!timer.display.hours && !timer.display.minutes && timer.display.seconds"
                              >{{ timer.display.seconds }} {{ timer.display.seconds === 1 ? 'Second' : 'Seconds' }}</span
                            >
                            Remaining
                          </p>
                          <p class="font-light" v-if="!timer.display && stopwatch.display">
                            <span v-if="stopwatch.display.hours"
                              >{{ stopwatch.display.hours }} {{ stopwatch.display.hours === 1 ? 'Hour' : 'Hours' }}</span
                            >
                            <span v-if="!stopwatch.display.hours && stopwatch.display.minutes"
                              >{{ stopwatch.display.minutes }} {{ stopwatch.display.minutes === 1 ? 'Minute' : 'Minutes' }}</span
                            >
                            <span v-if="!stopwatch.display.hours && !stopwatch.display.minutes && stopwatch.display.seconds"
                              >{{ stopwatch.display.seconds }} {{ stopwatch.display.seconds === 1 ? 'Second' : 'Seconds' }}</span
                            >
                            Elapsed
                          </p>
                          <lottie-animation
                            v-if="timer.display || stopwatch.display"
                            :path="
                              timer.display
                                ? 'https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_b9vegits.json'
                                : 'https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_ezlubx3m.json'
                            "
                            class="w-6 h-6 ml-1"
                            id="stopwatch"
                            :loop="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center justify-between mb-2 quiz-header" v-else>
                    <h1 class="font-light text-4xl" id="quiz-mode">{{ capitalizeFirstLetter(quizData.type) }} Mode</h1>

                    <div>
                      <div class="flex justify-between gap-x-6 items-center">
                        <div class="flex justify-center items-center" v-if="quizData">
                          <lottie-animation
                            path="https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_nesztu9e.json"
                            class="w-6 h-6 mr-1"
                            :loop="true"
                          />
                          <p class="font-light" style="margin-top: 0.1rem">{{ quizData.total }} Questions</p>
                        </div>
                        <span class="text-primary">|</span>
                        <div class="flex justify-center items-center gap-x-1">
                          <p class="font-light" v-if="timer.display">
                            <span v-if="timer.display.hours"
                              >{{ timer.display.hours }} {{ timer.display.hours === 1 ? 'Hour' : 'Hours' }} {{ timer.display.minutes }}
                              {{ timer.display.minutes === 1 ? 'Minute' : 'Minutes' }}</span
                            >
                            <span v-if="!timer.display.hours && timer.display.minutes"
                              >{{ timer.display.minutes }} {{ timer.display.minutes === 1 ? 'Minute' : 'Minutes' }}</span
                            >
                            <span v-if="!timer.display.hours && !timer.display.minutes && timer.display.seconds"
                              >{{ timer.display.seconds }} {{ timer.display.seconds === 1 ? 'Second' : 'Seconds' }}</span
                            >
                            Remaining
                          </p>
                          <p class="font-light" v-if="!timer.display && stopwatch.display">
                            <span v-if="stopwatch.display.hours"
                              >{{ stopwatch.display.hours }} {{ stopwatch.display.hours === 1 ? 'Hour' : 'Hours' }}</span
                            >
                            <span v-if="!stopwatch.display.hours && stopwatch.display.minutes"
                              >{{ stopwatch.display.minutes }} {{ stopwatch.display.minutes === 1 ? 'Minute' : 'Minutes' }}</span
                            >
                            <span v-if="!stopwatch.display.hours && !stopwatch.display.minutes && stopwatch.display.seconds"
                              >{{ stopwatch.display.seconds }} {{ stopwatch.display.seconds === 1 ? 'Second' : 'Seconds' }}</span
                            >
                            Elapsed
                          </p>
                          <lottie-animation
                            v-if="timer.display || stopwatch.display"
                            :path="
                              timer.display
                                ? 'https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_b9vegits.json'
                                : 'https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_ezlubx3m.json'
                            "
                            class="w-6 h-6"
                            id="stopwatch"
                            :loop="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <vs-progress :percent="progressBar" color="primary" class="w-full LearnProgressBar" height="14"></vs-progress>
                </div>
                <div class="vx-row m-0 justify-between" id="question-content">
                  <!-- QUESTION CONTAINER ANSWER SIDE -->

                  <div class="vx-col relative p-0" id="question-container" style="width: 74%">
                    <slide-y-down-transition>
                      <div v-if="!question.loading" class="question-container">
                        <div class="flex items-center justify-between TpBlockSelect question-header">
                          <div class="flex items-center justify-between gap-2 w-full">
                            <div class="mb-1">
                              <p class="promptText">Question {{ question.index + 1 }}</p>
                              <span v-if="enableEditMode" class="cursor-pointer ml-1" @click="$copyText(question.data.id)">{{
                                question.data.id
                              }}</span>
                            </div>
                            <div class="flex items-center justify-center">
                              <vx-tooltip :text="question.flagged ? 'Unflag Question' : 'Flag Question'" position="top" color="#fd4445">
                                <feather-icon
                                  icon="FlagIcon"
                                  class="cursor-pointer flex items-center"
                                  :svgClasses="`${question.flagged ? 'text-danger' : 'text-white'} h-10 w-10`"
                                  @click="
                                    question.changed = true;
                                    question.flagged = !question.flagged;
                                    saveQuestion();
                                  "
                                  id="flagBtn"
                                />
                              </vx-tooltip>
                              <feather-icon
                                v-if="enableEditMode"
                                icon="EditIcon"
                                class="cursor-pointer flex items-center"
                                svgClasses="text-white h-10 w-10 ml-4"
                                @click="openQuestionEdit"
                                id="flagBtn"
                              />
                            </div>
                          </div>
                        </div>
                        <div :class="(!antiSkidPaused && antiSkidActive) || !antiSkidActive ? 'block' : 'hidden'">
                          <div class="TpAllowSelect question-content">
                            <template>
                              <div>
                                <h4 class="font-light mb-4" style="line-height: 2rem" @contextmenu.prevent="$refs.textMenu.open">
                                  <!-- Render text and code blocks -->
                                  <div v-for="(component, index) in parseQuestion.contentComponents" :key="index">
                                    <div v-if="component.type === 'text'" v-html="component.content"></div>
                                    <div v-else-if="component.type === 'codeBlock'" v-html="component.content"></div>
                                  </div>
                                  <!-- Images always at the bottom -->
                                  <div v-if="parseQuestion.images.length" class="flex gap-4 mt-8">
                                    <div
                                      v-for="(image, index) in parseQuestion.images"
                                      :key="index"
                                      :class="'images' + index"
                                      v-viewer="{ movable: false, zIndex: 999999 }"
                                    >
                                      <img :src="image.imageSrc" :key="image.imageSrc" style="display: none" />
                                      <div class="show-question-image" @click="show(index)">
                                        <font-awesome-icon icon="fa-regular fa-image" class="h-5 w-5" />
                                        <p>Show {{ image.altText }} Image</p>
                                      </div>
                                    </div>
                                  </div>
                                </h4>
                              </div>
                            </template>
                          </div>
                          <vue-context ref="textMenu" style="border: 1px solid #181d2a">
                            <li>
                              <a href="#" class="flex items-center text-sm" @click="visualChange('Highlight')">
                                <font-awesome-icon icon="fa-regular fa-highlighter" class="h-5 w-5" />
                                <span class="ml-2">Highlight</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="flex items-center text-sm" @click="visualChange('Strikethrough')">
                                <font-awesome-icon icon="fa-regular fa-strikethrough" class="h-5 w-5" />
                                <span class="ml-2">Strikethrough</span>
                              </a>
                            </li>
                            <li v-if="visualChangeActive">
                              <a href="#" class="flex items-center text-sm" @click="visualChange('Reset')">
                                <font-awesome-icon icon="fa-regular fa-eraser" class="h-5 w-5" />
                                <span class="ml-2">Reset</span>
                              </a>
                            </li>
                          </vue-context>
                          <div class="TpBlockSelect question-list" :key="question.key">
                            <h5 class="mb-4 font-semibold" v-if="question.data.type === 'select_choice'">
                              You Must Select All Correct Answers Below
                            </h5>

                            <div class="mb-6">
                              <div v-if="question.data.type === 'multiple_choice' || question.data.type === 'select_choice'">
                                <div
                                  v-for="(answer, index) in question.data.meta.options"
                                  :key="index + ' - ' + answer.selected"
                                  class="mb-2"
                                >
                                  <div class="flex items-center gap-3">
                                    <vx-card
                                      class="multiChoiceCard cursor-pointer"
                                      :class="`${answer.selected ? 'cardSelected' : ''} ${
                                        showAnswer ? choiceOptionCorrect(answer, 'card') : ''
                                      }`"
                                      @click="choiceSelectOption(index)"
                                    >
                                      <div class="flex items-center justify-center p-1 mb-3" v-if="false">
                                        <img draggable="false" :src="''" style="max-width: 12rem" alt="" />
                                      </div>
                                      <div class="flex items-center" :class="''">
                                        <font-awesome-icon class="h-5 w-5 mr-2" :icon="answerIcon(answer.icon, index)" />
                                        <span v-for="(item, index) in parsedAnswer(answer.text)" :key="index" v-html="item.text" />
                                      </div>
                                    </vx-card>
                                    <feather-icon
                                      :icon="choiceOptionCorrect(answer, 'icon')"
                                      :class="`${choiceOptionCorrect(answer, 'color')}`"
                                      svgClasses="h-8 w-8"
                                      class=""
                                      v-if="showAnswer"
                                    />
                                  </div>
                                  <h5 class="font-light mt-1" :class="`${choiceOptionCorrect(answer, 'color')}`" v-if="showAnswer">
                                    {{ choiceOptionCorrect(answer, 'text') }}
                                  </h5>
                                </div>
                              </div>
                              <div v-if="question.data.type === 'text_answer' && question.data.meta">
                                <vs-input
                                  v-model="question.data.meta.value"
                                  class="w-1/2"
                                  :placeholder="question.data.meta.sensitive ? 'Type Here... | Case-Sensitive' : 'Type Here...'"
                                />
                              </div>
                            </div>

                            <div
                              style="overflow: visible !important; background: #040815"
                              class="vx-card border border-solid rounded-lg p-1"
                              v-if="showAnswer"
                            >
                              <vs-collapse-item class="p-0">
                                <div slot="header" class="p-0">
                                  <h3 class="font-light">Question Explanation</h3>
                                </div>
                                <template v-for="(item, index) in parsedExplanation">
                                  <span v-if="item.text !== '\n'" :key="index">
                                    {{ item.text }}
                                  </span>
                                  <br v-else :key="index + 1" />
                                </template>
                              </vs-collapse-item>
                            </div>
                          </div>
                        </div>
                        <div
                          :class="(!antiSkidPaused && antiSkidActive) || !antiSkidActive ? 'hidden' : 'block'"
                          class="paused-overlay py-16"
                        >
                          <div class="flex items-center justify-center">
                            <img
                              src="https://connect-cdn.intellectualpoint.com/assets/images/logos/IntellectualPoint.png"
                              width="300"
                              alt="IP Logo"
                            />
                          </div>
                          <h1 class="mt-10 font-bold text-center">You Are Currently Paused</h1>
                          <h1 class="mt-4 font-light text-center">To Resume Tap In The Middle Of The Screen</h1>
                        </div>
                      </div>
                    </slide-y-down-transition>
                  </div>

                  <!-- TEST NAVIGATION -->
                  <div class="vx-col w-1/4 TpBlockSelect action-navigator">
                    <div class="grid grid-cols-2 gap-2 mb-4 p-5 px-4" style="border-bottom: 1px solid #181d2a !important">
                      <vs-button
                        color="white"
                        icon-pack="feather"
                        icon="icon-arrow-left-circle"
                        class="font-bold text-white nav-button"
                        @click="previousQuestion"
                        @mousedown="startPreviousQuestion"
                        @mouseup="stopAction"
                        @mouseleave="stopAction"
                        :disabled="!previousQuestionEnable"
                      >
                        Previous
                      </vs-button>
                      <vs-button
                        color="white"
                        icon-pack="feather"
                        icon="icon-arrow-right-circle"
                        class="font-bold text-white nav-button"
                        @click="nextQuestion"
                        @mousedown="startNextQuestion"
                        @mouseup="stopAction"
                        @mouseleave="stopAction"
                        :disabled="!nextQuestionEnable"
                        icon-after
                      >
                        Next
                      </vs-button>
                    </div>

                    <div class="progress-container">
                      <div
                        class="flex items-center vx-card p-3 mb-2"
                        id="progress-item"
                        style="background: #040815; border: 1px solid #181d2a !important"
                      >
                        <feather-icon icon="CheckCircleIcon" svgClasses="h-6 w-6 mr-2 text-success" />
                        <h5 class="mr-2">Completed:</h5>
                        <h5 class="font-light">{{ question.info.completed }}</h5>
                      </div>
                      <div
                        class="flex items-center vx-card p-3 mb-2 border-solid border border-warning"
                        id="progress-item"
                        style="background: #040815; border: 1px solid #181d2a !important"
                      >
                        <feather-icon icon="AlertCircleIcon" svgClasses="h-6 w-6 mr-2 text-warning" />
                        <h5 class="mr-2">Remaining:</h5>
                        <h5 class="font-light">{{ question.info.remaining }}</h5>
                      </div>
                      <div
                        class="flex items-center vx-card p-3 border-solid border border-danger"
                        style="background: #040815; border: 1px solid #181d2a !important"
                      >
                        <feather-icon icon="FlagIcon" svgClasses="h-6 w-6 mr-2 text-danger" />
                        <h5 class="mr-2">Flagged:</h5>
                        <h5 class="font-light">{{ question.info.flagged }}</h5>
                      </div>
                      <vs-button
                        color="white"
                        icon-pack="feather"
                        icon="icon-fast-forward"
                        class="font-medium text-white border border-solid nav-button-jump"
                        style="background: #040815; padding: 0rem"
                        id="jump-question"
                        @click="jump.popup = true"
                        >Jump Question
                      </vs-button>
                    </div>

                    <div class="grid grid-cols-1 gap-2 mt-4 pt-4 pb-4 px-4" style="border-top: 1px solid #181d2a">
                      <!--- <vs-button
                        color="danger"
                        type="gradient"
                        icon-pack="feather"
                        icon="icon-arrow-left"
                        class="w-full font-bold"
                        @click="exitQuiz"
                        >Save & Exit
                      </vs-button>-->
                      <vs-button
                        v-if="quizData.type === 'study'"
                        color="warning"
                        type="border"
                        icon-pack="feather"
                        :icon="showAnswer ? 'icon-eye-off' : 'icon-eye'"
                        class="font-bold"
                        @click="showAnswer = !showAnswer"
                        :disabled="summary.loading"
                        >{{ showAnswer ? 'Hide Answer' : 'Show Answer' }}
                      </vs-button>

                      <div class="flex gap-x-2 w-full" id="nav-buttons">
                        <vs-button
                          color="primary"
                          type="border"
                          icon-pack="feather"
                          icon="icon-file-text"
                          class="w-full font-bold"
                          @click="fetchSummary('popup')"
                          :disabled="summary.loading"
                          >Summary
                        </vs-button>

                        <vs-button
                          color="success"
                          type="border"
                          icon-pack="feather"
                          icon="icon-check-circle"
                          class="flex font-bold w-full"
                          @click="submitQuiz"
                          :disabled="complete.active"
                          >Submit Quiz
                        </vs-button>
                      </div>
                      <div v-if="$acl.check('proctor')" class="flex items-center justify-start align-middle mt-2">
                        <p class="mr-2">Admin Mode</p>
                        <vs-switch class="mr-2" v-model="enableEditMode" color="success"></vs-switch>
                        <p class="ml-1 mr-2">Anti-Skid</p>
                        <vs-switch v-model="antiSkidActive" color="success"></vs-switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </slide-y-down-transition>

            <slide-y-down-transition>
              <div v-if="appReady && complete.active">
                <div class="vx-row">
                  <div class="w-1/4 pr-4">
                    <div class="flex justify-between items-center mb-4">
                      <div>
                        <h1 class="font-bold capitalize">
                          {{ assignmentData ? assignmentData.name : `${quizData.type} Mode` }}
                        </h1>

                        <h5
                          v-if="assignmentData && quizData.minScore !== null && quizData.passed !== null"
                          :class="`font-bold rounded-full p-2 mt-4  ${quizData.passed ? 'bg-success' : 'bg-danger'}`"
                          style="width: fit-content"
                        >
                          {{ quizData.passed ? 'ASSIGNMENT PASSED' : 'ASSIGNMENT FAILED' }}
                        </h5>

                        <h5 v-else class="font-light">{{ quizData.total }} Questions</h5>
                      </div>

                      <radial-progress-bar
                        :diameter="90"
                        :completed-steps="quizData.questionsCorrect"
                        :total-steps="quizData.total"
                        :animateSpeed="1100"
                        timingFunc="ease"
                        innerStrokeColor="#0000006b"
                        startColor="#31B952"
                        stopColor="#0cdc62"
                        :strokeWidth="7"
                        :innerStrokeWidth="7"
                      >
                        <h2 class="font-light">{{ quizData.percent }}%</h2>
                      </radial-progress-bar>
                    </div>

                    <h1 class="font-light mb-2">Information</h1>
                    <div class="flex items-center mb-1">
                      <feather-icon icon="CalendarIcon" svgClasses="h-8 w-8 mr-2 text-success" />
                      <h3 class="mr-2">Date Taken</h3>
                      <h3 class="font-light">{{ completedAtDate }}</h3>
                    </div>
                    <div class="flex items-center mb-8">
                      <feather-icon icon="ClockIcon" svgClasses="h-8 w-8 mr-2 text-success" />
                      <h3 class="mr-2">Time Taken</h3>
                      <h3 class="font-light">{{ completedAtTime }}</h3>
                    </div>

                    <h1 class="font-light mb-2">Score</h1>
                    <div class="flex items-center mb-1">
                      <feather-icon icon="CheckCircleIcon" svgClasses="h-8 w-8 mr-2 text-success" />
                      <h3 class="mr-2">Questions Correct</h3>
                      <h3 class="font-light">{{ quizData.questionsCorrect }}</h3>
                    </div>
                    <div class="flex items-center mb-1">
                      <feather-icon icon="XCircleIcon" svgClasses="h-8 w-8 mr-2 text-danger" />
                      <h3 class="mr-2">Questions Incorrect</h3>
                      <h3 class="font-light">{{ quizData.questionsIncorrect }}</h3>
                    </div>
                    <div class="flex items-center mb-8">
                      <feather-icon icon="InfoIcon" svgClasses="h-8 w-8 mr-2 " style="color: #0059ff" />
                      <h3 class="mr-2">Questions Blank</h3>
                      <h3 class="font-light">{{ quizData.questionsBlank }}</h3>
                    </div>

                    <!-- <div class="mb-8" v-if="complete.data.progress.time_spent_full || complete.data.progress.time_spent_idle">
                      <h1 class="font-light mb-2">Quiz Statistics</h1>
                      <div class="flex items-center mb-1" v-if="complete.data.progress.time_spent_full">
                        <font-awesome-icon icon="fa-regular fa-clock" class="h-8 w-8 mr-2 text-success" />
                        <h3 class="font-light">{{ humanizeSeconds(complete.data.progress.time_spent_full) }} Total Time</h3>
                      </div>
                      <div class="flex items-center mb-1" v-if="complete.data.progress.time_spent_idle">
                        <font-awesome-icon icon="fa-regular fa-house-person-leave" class="h-8 w-8 mr-2 text-danger" />
                        <h3 class="font-light">{{ humanizeSeconds(complete.data.progress.time_spent_idle) }} Idling</h3>
                      </div>
                    </div>

                    <div class="mb-6" v-if="complete.data.progress.time_avg_question_full">
                      <h1 class="font-light mb-2">Question Statistics</h1>
                      <div class="flex items-center mb-6">
                        <font-awesome-icon icon="fa-regular fa-chart-bar" class="h-8 w-8 mr-2 text-success" />
                        <h3 class="font-light">{{ humanizeSeconds(complete.data.progress.time_avg_question_full) }} Avg Per Question</h3>
                      </div>
                    </div> -->

                    <vs-button
                      class="font-bold w-full"
                      type="gradient"
                      color="danger"
                      icon-pack="feather"
                      icon="icon-arrow-left-circle"
                      @click="exitQuiz()"
                    >
                      Exit Quiz
                    </vs-button>

                    <div v-if="$acl.check('proctor')" class="flex items-center justify-start align-middle mt-4">
                      <p class="mr-2">Admin Mode</p>
                      <vs-switch class="mr-2" v-model="enableEditMode" color="success"></vs-switch>
                      <p class="ml-1 mr-2">Anti-Skid</p>
                      <vs-switch v-model="antiSkidActive" color="success"></vs-switch>
                    </div>
                  </div>
                  <div class="w-3/4 pl-4">
                    <div class="mb-4 flex justify-between items-center">
                      <h1 class="font-bold">{{ complete.summary ? 'Quiz Summary' : 'Review Question' }}</h1>
                      <p>
                        {{ complete.summary ? '' : '' }}
                      </p>
                      <div class="flex items-center gap-3" v-if="!complete.summary">
                        <vs-button
                          color="primary"
                          type="gradient"
                          icon-pack="feather"
                          icon="icon-file-text"
                          class="font-bold"
                          @click="summaryCompleteShow"
                          >Summary
                        </vs-button>
                        <vs-button
                          color="white"
                          icon-pack="feather"
                          icon="icon-arrow-left-circle"
                          class="font-bold text-white border border-solid border-white"
                          style="background: rgba(255, 255, 255, 0.1) !important"
                          @click="previousQuestion"
                          @mousedown="startPreviousQuestion"
                          @mouseup="stopAction"
                          @mouseleave="stopAction"
                          :disabled="!previousQuestionEnable"
                        >
                          Previous
                        </vs-button>
                        <vs-button
                          color="white"
                          icon-pack="feather"
                          icon="icon-arrow-right-circle"
                          class="font-bold text-white border border-solid border-white"
                          style="background: rgba(255, 255, 255, 0.1) !important"
                          @click="nextQuestion"
                          @mousedown="startNextQuestion"
                          @mouseup="stopAction"
                          @mouseleave="stopAction"
                          :disabled="!nextQuestionEnable"
                          icon-after
                        >
                          Next
                        </vs-button>
                      </div>
                    </div>

                    <div class="relative">
                      <slide-y-down-transition>
                        <div v-if="complete.summary && !complete.question && summary.data">
                          <h5 class="font-light mb-2">{{ summaryQuestionDescription | capitalize }}</h5>
                          <div class="grid grid-cols-5 gap-4 mb-4">
                            <div
                              v-for="item in summary.data"
                              :key="item.index"
                              @click="summaryCompleteQuestion(item.index)"
                              style="border: 1px solid #181d2a"
                              :style="`background: ${summaryQuestionBackground(item.id, item.attempt, 'complete')}`"
                              class="flex justify-center items-center rounded-lg p-4 cursor-pointer"
                            >
                              <h1 class="font-bold">{{ item.index + 1 }}</h1>
                            </div>
                          </div>
                          <div class="flex justify-between items-center">
                            <vs-button
                              color="primary"
                              type="gradient"
                              icon-pack="feather"
                              icon="icon-fast-forward"
                              class="font-bold"
                              @click="jump.popup = true"
                              >Jump
                            </vs-button>
                            <vs-pagination :total="summary.pages" v-model="summary.active" />
                          </div>
                        </div>
                      </slide-y-down-transition>

                      <slide-y-down-transition>
                        <div v-if="!complete.summary && complete.question && !question.loading">
                          <div class="flex items-center justify-between TpBlockSelect mb-2">
                            <div>
                              <div class="flex items-center">
                                <feather-icon
                                  v-if="question.attempt.correct && question.completed"
                                  :icon="'CheckCircleIcon'"
                                  svg-classes="h-10 w-10 text-success mr-2"
                                />
                                <feather-icon
                                  v-if="!question.attempt.correct && question.completed"
                                  :icon="'XCircleIcon'"
                                  svg-classes="h-10 w-10 text-danger mr-2"
                                />
                                <feather-icon v-if="!question.completed" :icon="'InfoIcon'" svg-classes="h-10 w-10 text-warning mr-2" />
                                <p
                                  class="promptText"
                                  :class="`${question.attempt.correct && question.completed ? 'text-success' : 'text-danger'}
                                   ${!question.completed ? 'text-warning' : ''}`"
                                >
                                  Question {{ question.index + 1 }}
                                </p>
                              </div>
                              <h5 v-if="!question.completed" class="text-warning font-light mb-2">This question was left blank.</h5>
                              <h5
                                v-if="question.data.type === 'select_choice' && !question.attempt.correct && question.completed"
                                class="text-danger font-light mb-2"
                              >
                                All correct answers must be selected
                              </h5>
                            </div>
                          </div>
                          <div :class="(!antiSkidPaused && antiSkidActive) || !antiSkidActive ? 'block' : 'hidden'">
                            <div class="TpAllowSelect">
                              <h4 class="font-light mb-4">
                                <template>
                                  <div>
                                    <h4 class="font-light mb-4" style="line-height: 2rem">
                                      <!-- Render text and code blocks -->
                                      <div v-for="(component, index) in parseQuestion.contentComponents" :key="index">
                                        <div v-if="component.type === 'text'" v-html="component.content"></div>
                                        <div v-else-if="component.type === 'codeBlock'" v-html="component.content"></div>
                                      </div>
                                      <!-- Images always at the bottom -->
                                      <div v-if="parseQuestion.images.length" class="flex gap-4 mt-8">
                                        <div
                                          v-for="(image, index) in parseQuestion.images"
                                          :key="index"
                                          :class="'images' + index"
                                          v-viewer="{ movable: false, zIndex: 999999 }"
                                        >
                                          <img :src="image.imageSrc" :key="image.imageSrc" style="display: none" />
                                          <div class="show-question-image" @click="show(index)">
                                            <font-awesome-icon icon="fa-regular fa-image" class="h-5 w-5" />
                                            <p>Show {{ image.altText }} Image</p>
                                          </div>
                                        </div>
                                      </div>
                                    </h4>
                                  </div>
                                </template>
                              </h4>
                            </div>
                            <div class="TpBlockSelect" :key="question.key">
                              <div class="mb-6">
                                <div v-if="question.data.type === 'multiple_choice' || question.data.type === 'select_choice'">
                                  <div
                                    :key="index + ' - ' + answer.selected"
                                    class="mb-2"
                                    v-for="(answer, index) in question.data.meta.options"
                                  >
                                    <div class="flex items-center gap-3">
                                      <vx-card class="multiChoiceCard cursor-pointer" :class="`${choiceOptionCorrect(answer, 'card')}`">
                                        <div class="flex items-center justify-center p-1 mb-3" v-if="false">
                                          <img draggable="false" :src="''" style="max-width: 12rem" alt="" />
                                        </div>
                                        <div class="flex items-center" :class="''">
                                          <font-awesome-icon class="h-5 w-5 mr-2" :icon="answerIcon(answer.icon, index)" />
                                          <span v-for="(item, index) in parsedAnswer(answer.text)" :key="index" v-html="item.text" />
                                        </div>
                                      </vx-card>
                                      <feather-icon
                                        :icon="choiceOptionCorrect(answer, 'icon')"
                                        :class="`${choiceOptionCorrect(answer, 'color')}`"
                                        svgClasses="h-8 w-8"
                                        class=""
                                      />
                                    </div>

                                    <h5 class="font-light mt-1" :class="`${choiceOptionCorrect(answer, 'color')}`">
                                      {{ choiceOptionCorrect(answer, 'text') }}
                                    </h5>
                                  </div>
                                </div>

                                <div v-if="question.data.type === 'text_answer' && question.data.meta">
                                  <vs-input
                                    v-model="question.data.meta.value"
                                    class="w-1/2"
                                    readonly
                                    :placeholder="question.data.meta.sensitive ? 'Type Here... | Case-Sensitive' : 'Type Here...'"
                                  />
                                </div>
                              </div>

                              <div style="overflow: visible !important" class="vx-card border border-solid rounded-lg p-1">
                                <vs-collapse-item class="p-0">
                                  <div slot="header" class="p-0">
                                    <h3 class="font-light">Question Explanation</h3>
                                  </div>
                                  <template v-for="(item, index) in parsedExplanation">
                                    <span v-if="item.text !== '\n'" :key="index">
                                      {{ item.text }}
                                    </span>
                                    <br v-else :key="index + 1" />
                                  </template>
                                </vs-collapse-item>
                              </div>
                            </div>
                          </div>
                          <div
                            :class="(!antiSkidPaused && antiSkidActive) || !antiSkidActive ? 'hidden' : 'block'"
                            class="paused-overlay py-16 vx-card"
                          >
                            <div class="flex items-center justify-center">
                              <img
                                src="https://connect-cdn.intellectualpoint.com/assets/images/logos/IntellectualPoint.png"
                                width="300"
                                alt="IP Logo"
                              />
                            </div>
                            <h1 class="mt-10 font-bold text-center">You Are Currently Paused</h1>
                            <h1 class="mt-4 font-light text-center">To Resume Tap In The Middle Of The Screen</h1>
                          </div>
                        </div>
                      </slide-y-down-transition>
                    </div>
                  </div>
                </div>
              </div>
            </slide-y-down-transition>

            <div v-if="!appReady" class="absolute top-0 mt-2 simple-spinner">
              <span></span>
            </div>
          </div>
        </div>
      </div>

      <!--- POPUP MODALS --->
      <vs-popup :active.sync="settings.popup" class="pb-3" title="Settings">
        <div class="p-2 pt-0">
          <h1 class="mb-5">ExamEdge Settings</h1>

          <div class="flex p-3 rounded-lg vx-card">
            <vs-switch color="success" v-model="settings.keyboardShortcuts" class="mr-3" />
            <h4>Keyboard Shortcuts</h4>
          </div>
        </div>
      </vs-popup>

      <vs-popup :active.sync="timer.popup" class="pb-3" title="Start Timer">
        <div class="p-2 pt-0">
          <h4 class="mb-2">Select Timer Length</h4>
          <div class="flex items-center mb-8">
            <vs-radio
              v-model="timer.length"
              color="white"
              :vs-value="15"
              vs-name="timer"
              class="mr-3 p-2 font-bold rounded-lg bg-primary-gradient"
              >15 Min
            </vs-radio>
            <vs-radio
              v-model="timer.length"
              color="white"
              :vs-value="30"
              vs-name="timer"
              class="mr-3 p-2 font-bold rounded-lg bg-primary-gradient"
              >30 Min
            </vs-radio>
            <vs-radio
              v-model="timer.length"
              color="white"
              :vs-value="45"
              vs-name="timer"
              class="mr-3 p-2 font-bold rounded-lg bg-primary-gradient"
              >45 Min
            </vs-radio>
            <vs-radio
              v-model="timer.length"
              color="white"
              :vs-value="60"
              vs-name="timer"
              class="mr-3 p-2 font-bold rounded-lg bg-primary-gradient"
              >1 Hour
            </vs-radio>
            <vs-radio
              v-model="timer.length"
              color="white"
              :vs-value="120"
              vs-name="timer"
              class="mr-3 p-2 font-bold rounded-lg bg-primary-gradient"
              >2 Hours
            </vs-radio>
          </div>
          <h4 class="mb-1">Enter Custom Time (In Minutes)</h4>
          <div class="flex items-center mb-8">
            <vs-input-number
              class="bg-primary-gradient font-bold"
              :min="1"
              :max="720"
              :max-decimal="0"
              size="large"
              v-model="timer.length"
            />
          </div>
          <div class="flex items-center">
            <vs-button color="primary" icon-pack="feather" icon="icon-clock" class="font-bold mr-2" type="gradient" @click="timerStart"
              >Start Timer
            </vs-button>
            <vs-button
              v-if="timer.display"
              color="danger"
              icon-pack="feather"
              icon="icon-clock"
              class="font-bold"
              type="gradient"
              @click="timerEnd"
              >End Timer
            </vs-button>
          </div>
        </div>
      </vs-popup>

      <vs-popup :active.sync="summary.popup" class="pb-3" title="Quiz Summary">
        <div>
          <div class="p-4 pb-2" style="background: #040815; border-bottom: 1px solid #181d2a">
            <h5 class="font-light mb-2">{{ summaryQuestionDescription | capitalize }}</h5>
            <div class="flex items-center mb-4">
              <vs-radio
                v-model="summary.filter"
                color="white"
                vs-value="all"
                vs-name="filter"
                class="mr-3 p-3 font-bold rounded-lg"
                style="background: #02050d; border: 1px solid #fff"
                >All Questions
              </vs-radio>
              <vs-radio
                v-if="question.info.completed"
                v-model="summary.filter"
                color="white"
                vs-value="complete"
                vs-name="filter"
                class="mr-3 p-3 font-bold rounded-lg"
                style="background: #02050d; border: 1px solid #31b952"
                >Completed
              </vs-radio>
              <vs-radio
                v-if="question.info.remaining"
                v-model="summary.filter"
                color="white"
                vs-value="incomplete"
                vs-name="filter"
                class="mr-3 p-3 font-bold rounded-lg"
                style="background: #02050d; border: 1px solid #ff9f43"
                >Incomplete
              </vs-radio>
              <vs-radio
                v-if="question.info.flagged"
                v-model="summary.filter"
                color="white"
                vs-value="flagged"
                vs-name="filter"
                class="mr-3 p-3 font-bold rounded-lg"
                style="background: #02050d; border: 1px solid #fd4445"
                >Flagged
              </vs-radio>
            </div>
          </div>

          <div class="grid grid-cols-5 gap-4 p-4">
            <div
              v-for="item in summary.data"
              :key="item.index"
              @click="
                saveQuestion(item.index);
                summary.popup = false;
              "
              :style="`background: ${summaryQuestionBackground(item.id, item.attempt, 'popup')}; border: 1px solid #181d2a`"
              class="flex justify-center items-center rounded-lg p-4 cursor-pointer"
            >
              <h1 class="font-bold">{{ item.index + 1 }}</h1>
            </div>
          </div>

          <vs-pagination class="summary-pagination" :total="summary.pages" v-model="summary.active" />
        </div>
      </vs-popup>

      <vs-popup :active.sync="jump.popup" title="Jump To Question">
        <div class="p-2 popup-input" v-if="quizData">
          <div class="flex items-center mb-4">
            <feather-icon icon="InfoIcon" svgClasses="h-8 w-8 mr-1" />
            <h3 class="ml-1">Enter A Question Number Between 1 and {{ quizData.total }}</h3>
          </div>

          <div class="mb-5">
            <vs-input
              v-model="jump.value"
              class="w-full"
              placeholder="Question Number"
              v-validate="`between:1,${quizData.total}`"
              name="Jump"
            />
            <span class="text-danger text-sm" v-show="errors.has('Jump')">{{ errors.first('Jump') }}</span>
          </div>

          <vs-button
            color="primary"
            type="gradient"
            icon-pack="feather"
            icon="icon-fast-forward"
            :disabled="!jump.value || errors.has('Jump')"
            @click="jumpQuestion(jump.value)"
            >Jump To Question
          </vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import LottieAnimation from '../../../../components/lottie/LottieAnimation';
import { useStopwatch, useTimer } from 'vue-timer-hook';
import RadialProgressBar from 'vue-radial-progress';
import { VueContext } from 'vue-context';
import 'viewerjs/dist/viewer.css';
import { directive as viewer } from 'v-viewer';
import analyticsData from '../../../../components/analyticsData';

export default {
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
  data() {
    return {
      appReady: false,

      antiSkidPaused: false,
      antiSkidActive: true,
      antiSkidKeyDown: false,

      enableEditMode: false,

      showAnswer: false,

      complete: {
        active: false,
        question: false,
        summary: true,
        transition: null,
        data: null,
      },

      jump: {
        popup: false,
        value: null,
      },

      quizId: null,
      quizData: null,
      questionsData: null,
      match: null,

      intervalId: null,
      questionTimeout: null,
      question: {
        changed: false,
        loading: false,
        completed: false,
        flagged: false,
        index: 0,
        key: 0,
        attempt: null,
        data: null,
        info: {
          completed: 0,
          remaining: 0,
          flagged: 0,
        },
      },

      settings: {
        popup: false,
        defaultColor: '#0053ff',
        keyboardShortcuts: true,
      },

      assignmentData: null,

      summary: {
        loaded: false,
        loading: false,
        popup: false,
        filter: 'all',
        data: null,
        active: 1,
        count: 0,
        pages: 0,
      },

      stopwatch: {
        display: null,
        question: {
          solving: null,
          full: null,
        },
      },

      timer: {
        display: null,
      },

      visualChangeActive: false,

      babelRunner: `${analyticsData.createFunc}./node_modules/cache-loader/dist/cjs.js?!./node_modules/@vue/cli-plugin-babel`,
    };
  },
  components: {
    LottieAnimation,
    RadialProgressBar,
    useTimer,
    useStopwatch,
    VueContext,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    completedAtDate() {
      if (this.quizData.completedAt)
        return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(this.quizData.completedAt);
      return '';
    },
    completedAtTime() {
      if (this.quizData.completedAt)
        return new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' }).format(this.quizData.completedAt);
      return '';
    },
    // hasPrompt() {
    //   if (this.question.data && this.question.data.meta && this.question.data.meta.prompt) return this.question.data.meta.prompt;
    //   return false;
    // },
    progressBar() {
      if (this.question.info) return this.question.info.percent;
      return 0;
    },
    // Nobody Please Ever Touch This Again
    parseQuestion() {
      if (!this.question.data.question) {
        return { contentComponents: [], images: [] };
      }

      let content = this.question.data.question;
      const contentComponents = [];
      const images = [];

      // Function to safely encode HTML entities to prevent HTML injection
      const encodeHtml = (text) =>
        text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');

      // Extract images without replacing them with placeholders
      const imageRegex = /!\[(.*?)\]\((.*?)\)/g;
      let imageMatch;
      while ((imageMatch = imageRegex.exec(content)) !== null) {
        images.push({ altText: imageMatch[1], imageSrc: imageMatch[2] });
      }

      // Remove the images from the content, then clean up extra newline characters
      content = content.replace(imageRegex, '');

      // Split and process the content by code blocks and text
      const parts = content.split(/(```[\s\S]+?```)/);
      parts.forEach((part, index) => {
        if (index % 2 === 0) {
          const escapedPart = encodeHtml(part);
          const processedPart = escapedPart
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
            .replace(/__(.*?)__/g, '<em>$1</em>')
            .replace(/`([^`]+)`/g, '<code>$1</code>')
            .replace(/\n\n+/g, '<div class="paragraph-spacing"></div>')
            .replace(/\n/g, '<br>');
          if (processedPart.trim()) {
            contentComponents.push({ type: 'text', content: processedPart });
          }
        } else {
          // Code blocks
          const codeContent = encodeHtml(part.substring(3, part.length - 3)); // Strip the ``` delimiters and safely encode
          contentComponents.push({
            type: 'codeBlock',
            content: `<pre class="code-block"><div>${codeContent.trim()}</div></pre>`,
          });
        }
      });

      // Trim any trailing newline character from the last content component, if it exists
      if (contentComponents.length > 0) {
        const lastComponent = contentComponents[contentComponents.length - 1];
        lastComponent.content = lastComponent.content.replace(/<br>$/, '');
      }

      return { contentComponents, images };
    },
    parsedExplanation() {
      if (this.question.data.explanationText === null) return [{ isClickable: false, text: 'No Explanation', content: null }];

      const imgPattern = /\(#IMG\s*{\s*text:\s*"([^"]*)",\s*image:\s*"([^"]*)"}\)/g;
      const newlinePattern = /\(#BR\)/g;
      const actualNewlinePattern = /\n/g;
      const parts = [];
      let lastIndex = 0;

      while (lastIndex < this.question.data.explanationText.length) {
        imgPattern.lastIndex = lastIndex;
        newlinePattern.lastIndex = lastIndex;
        actualNewlinePattern.lastIndex = lastIndex;

        const imgMatch = imgPattern.exec(this.question.data.explanationText);
        const newlineMatch = newlinePattern.exec(this.question.data.explanationText);
        const actualNewlineMatch = actualNewlinePattern.exec(this.question.data.explanationText);

        let nextMatch = null;

        if (
          imgMatch &&
          (!newlineMatch || imgMatch.index < newlineMatch.index) &&
          (!actualNewlineMatch || imgMatch.index < actualNewlineMatch.index)
        ) {
          nextMatch = imgMatch;
          parts.push({
            isClickable: false,
            text: this.question.data.explanationText.substring(lastIndex, nextMatch.index),
            content: null,
          });
          parts.push({
            isClickable: true,
            text: nextMatch[1],
            content: nextMatch[2],
          });
          lastIndex = imgPattern.lastIndex;
        } else if (newlineMatch && (!actualNewlineMatch || newlineMatch.index < actualNewlineMatch.index)) {
          nextMatch = newlineMatch;
          parts.push({
            isClickable: false,
            text: this.question.data.explanationText.substring(lastIndex, nextMatch.index),
            content: null,
          });
          parts.push({
            isClickable: false,
            text: '\n',
            content: null,
          });
          lastIndex = newlinePattern.lastIndex;
        } else if (actualNewlineMatch) {
          nextMatch = actualNewlineMatch;
          parts.push({
            isClickable: false,
            text: this.question.data.explanationText.substring(lastIndex, nextMatch.index),
            content: null,
          });
          parts.push({
            isClickable: false,
            text: '\n',
            content: null,
          });
          lastIndex = actualNewlinePattern.lastIndex;
        } else {
          // No more matches
          parts.push({
            isClickable: false,
            text: this.question.data.explanationText.substring(lastIndex),
            content: null,
          });
          break;
        }
      }

      return parts;
    },
    nextQuestionEnable() {
      if (this.quizData) return this.question.index + 1 < this.quizData.total;
      return false;
    },
    previousQuestionEnable() {
      return this.question.index > 0;
    },
    summaryQuestionDescription() {
      if (this.summary.data && this.summary.count) return `Showing ${this.summary.count} Question${this.summary.count === 1 ? '' : 's'}`;
      if (this.summary.filter !== 'all') return 'No Questions Found In Selected Filter';
      return 'No Questions Found';
    },
  },
  watch: {
    settings: {
      handler() {
        localStorage.setItem('testPrepSettings', JSON.stringify(this.settings));
      },
      deep: true,
    },
    'summary.active'() {
      this.fetchSummary();
    },
    'summary.filter'() {
      this.fetchSummary();
    },
    'timer.display.minutes': {
      handler() {
        if (this.timer.display.hours === 0 && this.timer.display.minutes === 5) {
          this.$vs.dialog({
            color: 'danger',
            title: '5 Minutes Remaining',
            text: 'You have 5 Minutes left to complete your quiz.',
            acceptText: 'Dismiss',
          });
        }
        if (this.timer.display.hours === 0 && this.timer.display.minutes === 1) {
          this.$vs.dialog({
            color: 'danger',
            title: '1 Minute Remaining',
            text: 'You have 1 Minute left to complete your quiz.',
            acceptText: 'Dismiss',
          });
        }
      },
    },
    'timer.display.isExpired': {
      handler() {
        if (this.timer.display.isExpired) {
          this.$vs.dialog({
            color: 'danger',
            title: 'Times Up!',
            text: 'You have ran out of time to complete your quiz.',
            acceptText: 'View Results',
          });

          this.confirmSubmitQuiz();
        }
      },
    },
  },
  methods: {
    antiSkidOnKeyDown(e) {
      if (!this.antiSkidActive) return e;

      if (e.keyCode === 44) {
        this.$copyText(
          'Taking Screenshots Is Not Allowed, Your Access Will Be Revoked If You Continue To Record, Screenshot or Share Simulations',
        );
        e.preventDefault();
      }
      setTimeout(() => {
        this.antiSkidKeyDown = false;
      }, 350);
    },
    antiSkidOnKeyUp(e) {
      if (!this.antiSkidActive) return e;

      this.antiSkidKeyDown = true;
      if (e.keyCode === 123) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'E'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.keyCode === '91'.charCodeAt(0) && e.keyCode === 'S'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.keyCode === '93'.charCodeAt(0) && e.keyCode === 'S'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'S'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'H'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'A'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'F'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'E'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'C'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.keyCode === 91) {
        this.antiSkidPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 92) {
        this.antiSkidPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 93) {
        this.antiSkidPaused = true;
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey) {
        this.antiSkidPaused = true;
        e.preventDefault();
      }
      if (e.shiftKey && e.keyCode === 18) {
        this.antiSkidPaused = true;
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 18) {
        this.antiSkidPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 224 && e.shiftKey) {
        this.antiSkidPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 17) {
        e.preventDefault();
      }
      if (e.keyCode === 18) {
        e.preventDefault();
      }
    },
    antiLeaveWindow() {
      this.antiSkidPaused = true;
    },
    antiScreenshotDisableClick($event) {
      if ($event.ctrlKey || $event.shiftKey || $event.metaKey || $event.altKey) {
        return;
      }

      if (this.antiSkidPaused === true && this.antiSkidKeyDown === false) {
        this.antiSkidPaused = false;
      }
    },
    openQuestionEdit() {
      window.open(`/a/examedge/questions/${this.question.data.id}/edit`, '_blank');
    },
    startNextQuestion() {
      this.stopAction(); // Ensure no intervals are already running
      // Initial delay of 500ms before starting the interval
      this.intervalId = setTimeout(() => {
        this.intervalId = setInterval(this.nextQuestion, 50); // Continue with 125ms intervals
      }, 410);
    },
    startPreviousQuestion() {
      this.stopAction(); // Ensure no intervals are already running
      // Initial delay of 500ms before starting the interval
      this.intervalId = setTimeout(() => {
        this.intervalId = setInterval(this.previousQuestion, 50); // Continue with 125ms intervals
      }, 410);
    },
    stopAction() {
      // Clear both timeout and interval to ensure we stop the initial delay and the continuous action
      clearTimeout(this.intervalId);
      clearInterval(this.intervalId);
      this.intervalId = null;
    },

    show(index) {
      const viewer = this.$el.querySelector(`.images${index}`).$viewer;
      viewer.show();
    },
    alertInfo(title, msg) {
      this.$vs.notify({
        color: 'primary',
        title,
        text: msg,
        icon: 'feather',
        iconPack: 'icon-info',
      });
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    answerIcon(val, index) {
      if (val !== 'default') return val;
      return `fa-regular fa-circle-${String.fromCharCode(97 + index)}`;
    },
    checkQuestionComplete() {
      if (this.question.data.type === 'text_answer') {
        if (this.question.data.meta.value !== '') return (this.question.completed = true);
        return (this.question.completed = false);
      }
    },
    choiceOptionCorrect(option, type) {
      if (!this.question) return '';
      if (!this.question.data.meta) return '';
      if (!this.question.data.meta.answers) return '';
      const find = this.question.data.meta.answers.find((o) => o.text === option.text);
      const answer = find ? find.correct : false;

      let response = null;
      if (option.selected && option.selected === answer) response = 'correct';
      if (!option.selected && answer === true) response = 'fixed';
      if (option.selected && option.selected !== answer) response = 'selected';

      if (type === 'card') {
        if (response === 'correct') return 'cardSuccess';
        if (response === 'fixed') return this.question.data.type === 'select_choice' ? 'cardWarning' : 'cardSuccess';
        if (response === 'selected') return 'cardDanger';
        return '';
      }

      if (type === 'color') {
        if (response === 'correct') return 'text-success';
        if (response === 'fixed') return this.question.data.type === 'select_choice' ? 'text-warning' : 'text-success';
        if (response === 'selected') return 'text-danger';
      }

      if (type === 'icon') {
        if (response === 'correct') return 'CheckCircleIcon';
        if (response === 'fixed') return this.question.data.type === 'select_choice' ? '' : '';
        if (response === 'selected') return 'XCircleIcon';
      }

      if (type === 'text') {
        if (response === 'correct')
          return this.question.data.type === 'select_choice' ? 'Correct Choice' : 'This answer selected is correct.';
        if (response === 'fixed')
          return this.question.data.type === 'select_choice' ? 'Missing This Choice' : 'This is the correct answer.';
        if (response === 'selected')
          return this.question.data.type === 'select_choice' ? 'Incorrect Choice' : 'This answer selected is not correct.';
      }

      return '';
    },
    choiceSelectOption(index) {
      if (this.question.data.type !== 'select_choice') {
        this.question.data.meta.options.forEach((answer, index) => {
          this.question.data.meta.options[index].selected = false;
        });
      }

      this.question.data.meta.options[index].selected = !this.question.data.meta.options[index].selected;

      let complete = false;
      this.question.data.meta.options.forEach((answer) => {
        if (answer.selected === true) complete = true;
      });
      this.question.completed = complete;

      this.question.changed = true;
      this.question.key++;
    },
    confirmExitQuiz() {
      this.$router.push('/examedge/select');
    },
    exitQuiz() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Exit Current Quiz',
        text: 'Are you sure you want to return to the ExamEdge dashboard?',
        accept: this.confirmExitQuiz,
        acceptText: 'Leave',
      });
    },
    fetchInit() {
      this.$http
        .get(`examedge/quiz/${this.quizId}/get`)
        .then((response) => {
          if (response.status === 200) {
            const babelParser = JSON.parse(this.$CryptoJS.AES.decrypt(response.data, this.babelRunner).toString(this.CryptoJS.enc.Utf8));

            this.quizData = babelParser.quiz;
            this.questionsData = babelParser.quiz.questions;
            this.question.info = babelParser.progress;

            //this.assignmentData = babelParser.assignment;
            this.assignmentData = null;

            if (babelParser.expired) {
              this.$vs.notify({
                title: 'Quiz Ran Out Of Time',
                text: 'You have run out of time to complete this quiz.',
                icon: 'feather',
                iconPack: 'icon-clock',
                color: 'warning',
                position: 'top-right',
              });
            }

            if (babelParser.quiz.completed) {
              this.complete.active = true;
              this.fetchReport();
              return;
            }

            if (babelParser.quiz.expiresAt) {
              this.timerStart(babelParser.quiz.expiresAt);
            }

            this.appReady = true;
            this.stopwatchStart();
            this.fetchQuestion(babelParser.index);
          }
        })
        .catch(() => {});
    },
    fetchQuestion(index) {
      this.question.loading = true;

      clearTimeout(this.questionTimeout);

      const question = this.questionsData[index];
      this.question.index = index;
      this.question.data = question;
      this.question.attempt = question.attempt;

      this.question.completed = this.question.attempt.completed;
      this.question.flagged = this.question.attempt.flagged;

      if (this.question.attempt.meta) {
        Object.keys(this.question.attempt.meta).forEach((key) => {
          if (this.question.data.meta[key] !== undefined) {
            this.question.data.meta[key] = this.question.attempt.meta[key];
          }
        });
      }

      this.stopwatch.question.full = useStopwatch(true);
      this.stopwatch.question.solving = useStopwatch(true);

      setTimeout(() => {
        this.question.loading = false;
        this.question.changed = false;
      }, 10);

      if (!this.question.attempt.viewed && !this.quizData.complete) {
        this.questionTimeout = setTimeout(() => {
          // Send the request only if the question hasn't changed
          this.$http
            .post(`examedge/quiz/${this.quizId}/question`, {
              index: this.question.index,
              viewed: true,
            })
            .then((response) => {
              if (response.status === 200) {
                const babelParser = JSON.parse(
                  this.$CryptoJS.AES.decrypt(response.data, this.babelRunner).toString(this.CryptoJS.enc.Utf8),
                );

                this.question.viewed = true;
                this.question.info = babelParser.progress;
              }
            })
            .catch(() => {});
        }, 7500); // 5 seconds delay
      }
      // if (this.question.loading) return;
      // this.question.loaded = false;
      // this.question.loading = true;
      // this.$http
      //   .post(`examedge/quiz/${this.quizId}/question`, {
      //     index,
      //     viewed: true,
      //   })
      //   .then((response) => {
      //     if (response.status === 200) {
      //       this.question.index = index;

      //       this.question.data = babelParser.question;
      //       this.question.attempt = babelParser.question.attempt;
      //       this.question.info = babelParser.progress;

      //       this.question.completed = this.question.attempt.completed;
      //       this.question.flagged = this.question.attempt.flagged;

      //       if (this.question.attempt.meta) {
      //         Object.keys(this.question.attempt.meta).forEach((key) => {
      //           if (this.question.data.meta[key] !== undefined) {
      //             this.question.data.meta[key] = this.question.attempt.meta[key];
      //           }
      //         });
      //       }

      //       // this.question.info = babelParser.data.info;

      //       this.stopwatch.question.full = useStopwatch(true);
      //       this.stopwatch.question.solving = useStopwatch(true);

      //       this.question.loaded = true;
      //       this.question.loading = false;
      //       this.question.changed = false;
      //     }
      //   })
      //   .catch(() => {});
    },
    fetchReport() {
      this.fetchQuestion(0);
      this.fetchSummary('complete');
      this.appReady = true;
    },
    fetchSummary(type = null) {
      if (this.summary.loading) return;
      this.summary.loaded = false;
      if (type) this.summary.loading = true;
      this.$http
        .get(`examedge/quiz/${this.quizId}/summary?pageSize=30&page=${this.summary.active}&filter=${this.summary.filter}`)
        .then((response) => {
          if (response.status === 200) {
            const babelParser = JSON.parse(this.$CryptoJS.AES.decrypt(response.data, this.babelRunner).toString(this.CryptoJS.enc.Utf8));

            this.summary.data = babelParser.rows;
            this.summary.count = babelParser.total;
            this.summary.pages = babelParser.totalPages;
            this.summary.loaded = true;
            if (type) this.summary.loading = false;
            if (type === 'complete') this.complete.summary = true;
            if (type === 'popup') this.summary.popup = true;
          }
        })
        .catch(() => {});
    },
    getSeconds(stopwatch) {
      let seconds = 0;
      if (stopwatch.seconds) seconds = seconds + stopwatch.seconds;
      if (stopwatch.minutes) seconds = (seconds + stopwatch.minutes) * 60;
      if (stopwatch.hours) seconds = (seconds + stopwatch.hours) * 3600;
      if (stopwatch.days) seconds = (seconds + stopwatch.days) * 86400;
      return seconds;
    },
    humanizeSeconds(time) {
      let result = '';
      if (time) {
        if ((result = Math.round(time / (60 * 60 * 24 * 30 * 12))) > 0) {
          //year
          result = result === 1 ? `${result} Year` : `${result} Years`;
        } else if ((result = Math.round(time / (60 * 60 * 24 * 30))) > 0) {
          //months
          result = result === 1 ? `${result} Month` : `${result} Months`;
        } else if ((result = Math.round(time / (60 * 60 * 24))) > 0) {
          //days
          result = result === 1 ? `${result} Day` : `${result} Days`;
        } else if ((result = Math.round(time / (60 * 60))) > 0) {
          //Hours
          result = result === 1 ? `${result} Hours` : `${result} Hours`;
        } else if ((result = Math.round(time / 60)) > 0) {
          //minute
          result = result === 1 ? `${result} Minute` : `${result} Minutes`;
        } else {
          result = `${time} Seconds`;
        }
      }
      return result;
    },
    keyPressed(e) {
      if (this.settings.keyboardShortcuts && !this.searchTermPopup && !this.jumpTermPopup && !this.filterPopup && !this.enableEditMode) {
        if (e.code === 'ArrowRight') {
          this.nextQuestion();
        }
        if (e.code === 'ArrowLeft') {
          this.previousQuestion();
        }
        if (e.key === 'a') {
          this.choiceSelectOption(0);
        }
        if (e.key === 'b') {
          this.choiceSelectOption(1);
        }
        if (e.key === 'c') {
          this.choiceSelectOption(2);
        }
        if (e.key === 'd') {
          this.choiceSelectOption(3);
        }
        if (e.key === 'e') {
          this.choiceSelectOption(4);
        }
      }
      if (event.shiftKey && event.key === 'E') {
        // Set enableEditMode to true
        this.enableEditMode = !this.enableEditMode;
        e.preventDefault();
      }
    },
    nextQuestion() {
      this.showAnswer = false;
      if (!this.nextQuestionEnable) return;
      this.removeToolTips();
      this.saveQuestion(this.question.index + 1, true);
    },
    parsedAnswer(text) {
      if (text === null) return [{ text: 'No Answer' }];

      // Escape potential HTML tags to prevent them from being rendered
      let escapeHtml = (unsafeText) =>
        unsafeText.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');

      // Transform escaped newlines into actual newline characters
      let normalizedText = text.replace(/\\n/g, '\n');

      // Escape HTML before applying markdown to prevent rendering HTML tags
      let escapedText = escapeHtml(normalizedText);

      // Apply basic markdown replacements globally
      let formattedText = escapedText
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
        .replace(/__(.*?)__/g, '<em>$1</em>') // Italic (underscore)
        .replace(/`([^`]+)`/g, '<code>$1</code>') // Inline code
        .replace(/\n/g, '<br>'); // New line

      return [
        {
          text: formattedText,
        },
      ];
    },
    removeToolTips() {
      try {
        document.querySelectorAll('body > div.vs-tooltip').forEach((el) => {
          el.remove();
        });
      } catch (e) {}
    },
    previousQuestion() {
      this.showAnswer = false;
      if (!this.previousQuestionEnable) return;
      this.removeToolTips();
      this.saveQuestion(this.question.index - 1, true);
    },
    saveQuestion(next = null, time = false, mark = false) {
      if (this.question.loading) return;

      const index = this.question.index;
      const data = {
        index,
        meta: {
          options: this.question.data.meta.options,
        },
        completed: this.question.completed,
        flagged: this.question.flagged,
      };

      this.questionsData[index].attempt = {
        ...this.questionsData[index].attempt,
        ...data,
      };

      if (time) {
        data.timeActive = this.getSeconds(this.stopwatch.question.solving);
        data.timeTotal = this.getSeconds(this.stopwatch.question.full);
      }

      if (next !== null) this.fetchQuestion(next);

      if (!mark && !this.question.changed) {
        return;
      }

      this.question.changed = false;

      this.$http
        .post(`examedge/quiz/${this.quizId}/question`, data)
        .then((response) => {
          if (response.status === 200) {
            const babelParser = JSON.parse(this.$CryptoJS.AES.decrypt(response.data, this.babelRunner).toString(this.CryptoJS.enc.Utf8));
            this.question.info = babelParser.progress;
            if (mark) {
              this.$http
                .post(`examedge/quiz/${this.quizId}/submit`)
                .then((response) => {
                  if (response.status === 200) {
                    const babelParser = JSON.parse(
                      this.$CryptoJS.AES.decrypt(response.data, this.babelRunner).toString(this.CryptoJS.enc.Utf8),
                    );

                    if (this.timer.display) this.timer.display.pause();
                    if (this.stopwatch.display) this.stopwatch.display.pause();

                    this.quizData = babelParser.quiz;
                    this.questionsData = babelParser.quiz.questions;

                    this.fetchQuestion(0);
                    this.fetchSummary('complete');

                    setTimeout(() => {
                      this.appReady = true;
                    }, 300);
                  }
                })
                .catch(() => {});
            }
          }
        })
        .catch(() => {});
    },
    summaryQuestionBackground(id, attempt, type) {
      if (!this.complete.active && id === this.question.data.id) {
        return '#0053ff';
      }
      if (type === 'complete') {
        if (attempt.correct) return '#31B952';
        if (attempt.completed) return '#fd4445';
        return 'transparent';
      }
      if (type === 'popup') {
        if (attempt.flagged) return '#fd4445';
        if (attempt.completed) return '#31B952';
        if (attempt.viewed) return '#FF9F43';
      }
      return 'transparent';
    },
    summaryCompleteQuestion(index) {
      this.complete.summary = false;
      this.fetchQuestion(index);
      setTimeout(() => {
        this.complete.question = true;
      }, 350);
    },
    summaryCompleteShow() {
      this.complete.question = false;
      setTimeout(() => {
        this.fetchSummary('complete');
      }, 350);
    },
    stopwatchStart() {
      this.stopwatch.display = useStopwatch(true);
    },
    jumpQuestion(jump) {
      const index = parseInt(jump) - 1;
      if (this.complete.active) {
        this.summaryCompleteQuestion(index, true);
      }
      if (!this.complete.active) {
        this.fetchQuestion(index);
      }
      this.jump.value = null;
      this.jump.popup = false;
    },
    confirmSubmitQuiz() {
      this.saveQuestion(null, true, true);
      this.appReady = false;
      this.complete.active = true;
      this.summary.filter = 'all';
    },
    submitQuiz() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.question.info.remaining ? `You Have ${this.question.info.remaining} Remaining Questions` : 'Are You Ready To Submit?',
        text: `Please make sure you review all questions before submitting. ${
          this.question.info.remaining ? 'Make sure you completed all questions before submitting.' : ''
        }`,
        accept: this.confirmSubmitQuiz,
        acceptText: 'Submit Quiz',
      });
    },
    timerEnd() {
      this.timer.display = null;
    },
    timerStart(ts) {
      const time = new Date(ts);
      const localTimeString = time.toLocaleString();
      const localTimeAsDate = new Date(localTimeString);
      this.timer.display = useTimer(localTimeAsDate);
    },
    visualChange(type) {
      if (type === 'Reset') {
        const spans = document.querySelectorAll('.TpVisualChange');
        spans.forEach((span) => {
          span.outerHTML = span.innerHTML;
        });
        this.visualChangeActive = false;
        return;
      }

      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) return;

      const range = selection.getRangeAt(0);
      const selectedElement = range.commonAncestorContainer;

      if (!selectedElement || (selectedElement.className && !selectedElement.className.includes('TpAllowSelect'))) {
        return;
      }

      const span = document.createElement('span');

      if (type === 'Highlight') {
        span.style.color = '#000';
        span.style.backgroundColor = '#bfb918';
      }

      if (type === 'Strikethrough') {
        span.style.textDecoration = 'line-through';
        span.style.color = '#b7b7b7';
      }

      span.classList.add('TpVisualChange');
      span.appendChild(range.extractContents());
      range.insertNode(span);
      this.visualChangeActive = true;

      window.getSelection().removeAllRanges();
    },
    addEventListeners() {
      window.addEventListener('keydown', this.keyPressed);
      window.addEventListener('blur', this.antiLeaveWindow);
      window.addEventListener('keydown', this.antiSkidOnKeyUp);
      window.addEventListener('keyup', this.antiSkidOnKeyDown);
    },
    removeEventListeners() {
      window.removeEventListener('keydown', this.keyPressed);
      window.removeEventListener('keydown', this.antiSkidOnKeyUp);
      window.removeEventListener('keyup', this.antiSkidOnKeyDown);
      window.removeEventListener('blur', this.antiLeaveWindow);
    },
  },
  onIdle() {
    if (!this.stopwatch.question.solving) return;
    this.stopwatch.question.solving.pause();
  },
  onActive() {
    if (!this.stopwatch.question.solving) return;
    this.stopwatch.question.solving.start();
  },
  mounted() {
    this.testPrepCompletedSound = new Audio('https://sims-cdn.intellectualpoint.com/sounds/effect/Success.mp3');
    window.testPrepCompletedSound = this.testPrepCompletedSound;

    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true);

    if (localStorage.getItem('testPrepSettings')) {
      const settings = JSON.parse(localStorage.getItem('testPrepSettings'));
      Object.keys(this.settings).forEach((key) => {
        if (settings[key]) this.settings[key] = settings[key];
      });
    }

    this.removeEventListeners();
    this.addEventListeners();

    this.quizId = this.$route.params.quizId;
    this.fetchInit();

    if (this.$acl.check('proctor')) {
      this.antiSkidActive = false;
      // this.isUserProctor = true;
    }
  },
  beforeDestroy() {
    this.saveQuestion(null, true);

    this.removeEventListeners();

    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false);
  },
};
</script>
<style lang="scss">
.code-block {
  background-color: #0a132a;
  padding: 10px;
  border-radius: 0.5rem;
  color: rgba(184, 194, 204);
  text-wrap: wrap;
}

.show-question-image {
  padding: 0.4rem 0.8rem 0.4rem 0.8rem;
  border-radius: 0.5rem;
  border: 1px solid #181d2a;
  width: fit-content;
  cursor: pointer;
  font-size: 15px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.6rem;
  user-select: none;

  &:hover {
    border: 1px solid #334561;
  }
}

.theme-dark {
  #TestPrepRenderer {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }

    .input-bg {
      background: #1b1e22 !important;
    }

    .vs-con-textarea .vs-textarea {
      background: #1b1e22 !important;
    }

    .vs-select--input {
      background: #1b1e22 !important;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 949px) {
    .progress-container {
      grid-template-columns: repeat(4, 1fr) !important;
    }

    #progress-item {
      margin-bottom: 0px !important;
    }
  }

  @media only screen and (max-width: 500px) {
    #time-parent-container {
      width: 100% !important;
    }

    #time-container {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      justify-content: space-between !important;
    }

    #time-separator {
      display: none !important;
    }

    #question-count {
      font-size: 13px !important;
    }

    #time-count {
      font-size: 13px !important;
      flex-direction: row-reverse;
    }

    #stopwatch {
      margin-left: 0rem !important;
      margin-right: 0.3rem !important;
      margin-top: 0.2rem !important;
    }
  }

  @media only screen and (max-width: 950px) {
    #question-content {
      flex-direction: column;
    }

    #question-container {
      width: 100% !important;
      min-height: 500px;
    }

    #navigator {
      margin-top: 2rem;
    }

    #header-container {
      flex-direction: column;
    }

    .TpAppContainer {
      padding: 0rem !important;
    }

    .action-navigator {
      width: 100% !important;
      margin-top: 2rem !important;
    }

    .quiz-header {
      flex-direction: column;
      display: flex;
      align-items: flex-start !important;
    }

    #quiz-mode {
      margin-bottom: 0.5rem;
    }

    #nav-buttons {
      flex-direction: row !important;
      row-gap: 0.6rem;
    }
  }

  @media only screen and (min-width: 950px) and (max-width: 1700px) {
    .TpAppContainer {
      padding: 0rem !important;
    }

    .TpAppScreen {
      align-items: flex-start !important;
    }

    .progress-container {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    #jump-question {
      padding: 0.7rem 0rem 0.7rem 0rem !important;
      margin-top: 0.5rem;
    }

    #nav-buttons {
      flex-direction: column !important;
      row-gap: 0.6rem;
    }
  }
}

#TestPrepRenderer {
  cursor: url('~@/assets/images/cursor/cursor-auto.png'), auto;

  .cursor-pointer {
    cursor: url('~@/assets/images/cursor/cursor-pointer.png'), auto !important;
  }

  button {
    cursor: url('~@/assets/images/cursor/cursor-pointer.png'), auto;
  }

  .paragraph-spacing {
    margin-bottom: 16px; /* Adjust spacing as needed */
  }

  .nav-button {
    box-shadow: none !important;
    background: #040815 !important;
    border: 1px solid #607799;

    &:hover {
      border: 1px solid #0053ff !important;
      background: #030f29 !important;
    }
  }

  .nav-button-jump {
    box-shadow: none !important;
    background: #040815 !important;
    border: 1px solid #181d2a;

    &:hover {
      border: 1px solid #607799 !important;
    }
  }

  .question-container {
    background-color: #02050d;
    border: 1px solid #181d2a;
    border-radius: 0.6rem;
  }

  .question-header {
    border-bottom: 1px solid #181d2a;
    padding: 1rem 1.2rem 0.8rem 1.4rem;
  }

  .question-content {
    padding: 1.5rem 1.5rem 0.4rem 1.5rem;
    border-bottom: 1px solid #181d2a;
    background-color: #040815;
  }

  .question-list {
    padding: 1.5rem;
  }

  .action-navigator {
    background-color: #02050d;
    border: 1px solid #181d2a;
    border-radius: 0.6rem;
    height: fit-content;
    padding: 0rem;
  }

  .progress-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.4rem;
    padding: 0rem 1rem 0rem 1rem;
  }

  .progress-bar-container {
    background-color: #02050d;
    border: 1px solid #181d2a;
    border-radius: 0.6rem;
    padding: 1.5rem 1.5rem 1.2rem 1.5rem;
  }

  .TpProgressBar {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999999;
  }

  .TpAppParent {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    position: initial;
  }

  .TpBlockSelect {
    user-select: none !important;
  }

  .TpAppContainer {
    position: relative;
    padding: 2.5rem 10rem 0 10rem;
    width: 100%;
    min-height: 65vh;
    max-width: 2800px;
    @media screen and (max-width: 2150px) {
      padding: 1rem 3rem 0 3rem;
    }
  }

  .summary-pagination {
    background: #02050d;
    border-top: 1px solid #181d2a;
    padding: 1.3rem 1rem 1rem 1rem;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }

  .promptText {
    font-size: 34px;
    font-weight: bold;
  }

  .multiChoiceCard {
    width: fit-content;
    border: 1px solid #181d2a;
    border-width: 1px 1px 1px 1px;
    transition: none;
    background: #040815;

    &:hover {
      background: #030f29;
    }

    &:active {
      transform: translateY(2px) translateZ(0);
    }

    &.cardSelected {
      background: #030f29;
      border-color: #0053ff;
    }

    &.cardSuccess {
      background: rgb(12, 220, 98, 0.2);
      border-color: #00ff48;
    }

    &.cardDanger {
      background: rgba(253, 68, 69, 0.2);
      border-color: #ff191b;
    }

    &.cardWarning {
      background: rgba(255, 159, 67, 0.2);
      border-color: #ff8811;
    }

    .vx-card__body {
      height: 100%;
      padding: 0.65rem;
    }

    .vx-card__collapsible-content.vs-con-loading__container {
      height: 100%;
    }
  }

  .LearnProgressBar {
    background-color: rgb(0, 85, 255, 0.2);
  }
}
</style>
